import React from "react"
import PropTypes from "prop-types"
// Components
import { graphql } from "gatsby"
import HeaderPost from "../header-post.component"
import BlogSummary from "../content/blog-summary.component"
import { Helmet } from "react-helmet"
import Footer from "../footer.component"
import PageSidebar from "../page-sidebar.component"
import BookListSidebar from "../book-list-sidebar"
import Navigation from "../navigation.component"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMdx    
  const bloggers = data.bloggers.edges.reduce((model, blogger)=> {
    model[blogger.node.basics.id] = {
        "name": blogger.node.basics.name,
        "email": blogger.node.basics.email
    }
    return model;        
  }, {});
  const tagHeader = `#${tag} (${totalCount})`    
  return (
    <div>      
      <Helmet>
        <title>bitCobblers {tagHeader}</title>      
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css"></link>
        <html lang="en" />
      </Helmet>
      <HeaderPost></HeaderPost>     
      <Navigation /> 
      <div className="max-w-screen-xl mx-auto p-5 space-y-4" > 
        <PageSidebar sidebar={<BookListSidebar books={pageContext.books}/>}>    
        {edges.map(({node}) => (          
           <BlogSummary key={node.frontmatter.slug} article={node} bloggers={bloggers}></BlogSummary> 
        ))}      
        </PageSidebar>
        {/* <Footer></Footer> */}
      </div>                 
    </div>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,              
              blogger: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              tags: PropTypes.array.isRequired
            }),
            excerpt : PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}
export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: {eq: true} } }

    ) {
      totalCount
      edges {
        node {          
          frontmatter {
            slug
            title
            blogger
            date
            tags
          }
          excerpt
        }
      }
    }
    bloggers: allResumeJson {
        edges {
          node {
            basics {
              id
              email
              name
            }
          }
        }
      }
  }
`
import * as React from "react"
import { Link } from "gatsby"
import gravatar from "../../gravatar"

const BlogSummary = ({article, bloggers}) => { 
    const {slug, blogger,tags,date, title} = article.frontmatter;
    return (
    <div id={slug} className="flex flex-col">                    
        <div className="flex justify-between  flex-row md:flex-row align-center">
            <div>
                <time className="text-sm font-bold text-blue-800/50" dateTime={date}>{date}</time>
                <h2 className="text-2xl text-blue-900">
                    <Link className="mr-2" to={slug}>{title}</Link>                       
                </h2>        
                <div>                                                                    
                    <div>
                    {tags.length > 0 && ( <span className="py-2 space-x-2">
                        {tags.map(tag => (
                            <Link key={tag} className="px-2 pb-1 text-sm lowercase rounded-md border border-purple-800/20 hover:bg-blue-500/5" to={"/tags/" + tag.replace("@", "") +"/"}>{tag}</Link>
                        ))}
                </span>)
                }
                    </div>
                </div>
            </div>                        
                <Link className="flex flex-col-reverse md:flex-row flex-none items-center justify-center" to={"/cobbler/" + blogger}>
                    <span className="p-2 hidden sm:inline-block hover:text-purple-800">{bloggers[blogger].name}</span> 
                    <img className="w-18 p-1 mx-5 md:mx-1 h-10 rounded-full" src={"//www.gravatar.com/avatar/" + gravatar(bloggers, blogger) + "?d=404&amp;s=250"} alt="Author" nopin="nopin"/>
                </Link>                  
        </div>

        <section className="text-md mt-2">
            <p>{article.excerpt} <Link to={slug} className="text-blue-700">»</Link></p>
        </section>          
    </div>
)}
BlogSummary.type = "b"
export default BlogSummary;